import React from 'react'
import Login from './Components/Login'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import CustomerList from './Components/CustomerList'
import Test from './Components/Test'
const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Login/>} />
        <Route exact path='/dashboard' element={<CustomerList/>} />
        <Route exact path='/test' element={<Test/>} />
        
      </Routes>
    </Router>
  )
}

export default App