import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Api from "../Utils/Api";
const Header = () => {
  const handleLogout = () => {


    var data = localStorage.getItem("titosuserref");

    Api.post(
      "/device/userlogout",
      {
        headers: {
          AuthToken: window.localStorage.getItem("titostoken"),
        },
      },
      data
    ).then((res) => {
      window.localStorage.removeItem("titosuserref");
      window.localStorage.removeItem("titostoken");
      
      window.location.href = "/";
    });
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="error">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            TITOS ADMIN
          </Typography>
          <Button
            color="inherit"
            onClick={() => {
              handleLogout();
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
