import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Header from "./Header";
import Api from "../Utils/Api";
import DatePicker from "react-date-picker";
import { Container } from "@mui/system";
import { Typography, Button, Tooltip } from "@mui/material";
import {write, writeFile, utils} from 'xlsx'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import PrintIcon from '@mui/icons-material/Print';
import jsPDF from 'jspdf'
import 'jspdf-autotable'

const columns = [
  { id: "BookingReference", label: "Booking Ref"},
  { id: "CustomerId", label: "Customer ID"},
  { id: "CustomerName", label: "Customer Name"},
  { id: "Phone", label: " Phone"},
  { id: "ClubID", label: "Club ID", align: "center"},
  { id: "Status", label: "Status"},
  { id: "actualCost", label: "Actual Cost"},
  { id: "afterDiscount", label: "After Discount"},
  { id: "UpdateOn", label: "Update On"},
  { id: "Date", label: "Event Date"},
  { id: "VoucherName", label: "Voucher name"},
  { id: "Quantity", label: "Quantity"},
  { id: "ExtPaymentRef", label: "ExtPaymentRef"},
];

const columnsForPdf = [
  { dataKey: "BookingReference", header: "Booking Ref"},
  { dataKey: "CustomerId", header: "Cust ID"},
  { dataKey: "CustomerName", header: "Cust Name"},
  { dataKey: "Phone", header: " Phone"},
  { dataKey: "ClubID", header: "Club ID"},
  { dataKey: "Status", header: "Status"},
  { dataKey: "actualCost", header: "Actual Cost"},
  { dataKey: "afterDiscount", header: "After Discount"},
  { dataKey: "UpdateOn", header: "Update On"},
  { dataKey: "Date", header: "Event Date"},
  { dataKey: "VoucherName", header: "Voucher Name"},
  { dataKey: "Quantity", header: "Quantity"},
  { dataKey: "ExtPaymentRef", header: "ExtPaymentRef"},
];

export default function CustomerList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [bookingList, setBookingList] = useState([]);
  const [rows, setRows] = useState([]);
  const [toDate, setToDate] = useState(new Date());
  const [toDateFormat, setToDateFormat] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date());
  const [fromDateFormat, setFromDateFormat] = useState(new Date());

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (!localStorage.getItem("titostoken")) {
      window.location.href = "/";
    }

    Api.get(`admin/fetchcustomerbookings?todate=${getFormattedDate(new Date())}&date=${getFormattedDate(new Date())}`, {
      headers: {
        AuthToken: window.localStorage.getItem("titostoken"),
      },
    }).then((res, err) => {
      if (res.data.Error !== null) {
        // console.log("err",err)
        console.log(res.data.Error.ErrorCode);
        if (res.data.Error.ErrorCode === 10002) {
          window.localStorage.removeItem("titostoken");
          window.localStorage.removeItem("titosuserrref");
          window.location.href = "/";
        }
      }

      console.log(res.data.Details);
      setBookingList(res.data);

      var dataList = [];
      res.data.Details.map((item) => {
        // console.log(item)
        var status;
        if (item.Status == 1) {
          status = "Active";
        } else {
          status = "Inactive";
        }

        dataList.push({
          BookingReference: item.BookingReference,
          CustomerId: item.CustomerID,
          CustomerName: item.CustomerName,
          Phone: item.CustomerPhone,
          ClubID: item.ClubID,
          Status: status,
          actualCost: item.BookingTransaction[0]?.ActualCost,
          afterDiscount: item.ActualCostAfterDiscount,
          UpdateOn: item.BookingTransaction[0]?.UpdatedOn,
          Date: item.EventDate,
          VoucherName: item.BookingTransaction[0]?.VoucherName,
          Quantity: item.BookingTransaction[0]?.Quantity,
          ExtPaymentRef: item.BookingTransaction[0]?.ExtPaymentRef,
        });
      });

      console.log("datalist=====>>>>>>>", dataList);

      setRows(dataList);
    });
  }, []);

  useEffect(()=>{
    if (!localStorage.getItem("titostoken")) {
      window.location.href = "/";
    }

    setFromDateFormat(getFormattedDate(fromDate))
    setToDateFormat(getFormattedDate(toDate))

    console.log("query====>>>>>>>",`admin/fetchcustomerbookings?todate=${toDateFormat}&date=${fromDateFormat}`)
    Api.get(`admin/fetchcustomerbookings?todate=${toDateFormat}&date=${fromDateFormat}`, {
      headers: {
        AuthToken: window.localStorage.getItem("titostoken"),
      },
    }).then((res, err) => {
      if (res.data.Error !== null) {
        // console.log("err",err)
        console.log(res.data.Error);
        if (res.data.Error.ErrorCode === 10002) {
          window.localStorage.removeItem("titostoken");
          window.localStorage.removeItem("titosuserrref");
          window.location.href = "/";
        }
      }

      console.log(res.data.Details);
      setBookingList(res.data);

      var dataList = [];
      res.data.Details.map((item) => {
        // console.log(item)
        var status;
        if (item.Status == 1) {
          status = "Active";
        } else {
          status = "Inactive";
        }

        dataList.push({
          BookingReference: item.BookingReference,
          CustomerId: item.CustomerID,
          CustomerName: item.CustomerName,
          Phone: item.CustomerPhone,
          ClubID: item.ClubID,
          Status: status,
          actualCost: item.BookingTransaction[0]?.ActualCost,
          afterDiscount: item.ActualCostAfterDiscount,
          UpdateOn: item.BookingTransaction[0]?.UpdatedOn,
          Date: item.EventDate,
          VoucherName: item.BookingTransaction[0]?.VoucherName,
          Quantity: item.BookingTransaction[0]?.Quantity,
          // TransactionID: item.BookingTransaction[0]?.TransactionRef,
          ExtPaymentRef: item.BookingTransaction[0]?.ExtPaymentRef,
        });
      });

      console.log("datalist=====>>>>>>>", dataList);

      setRows(dataList);
    });

  },[toDateFormat,fromDateFormat])

  const getFormattedDate = (e) => {
    const day = e.getDate();
    const month = e.getMonth() + 1;
    const year = e.getFullYear();
    const date = `${day}-${month}-${year}`;
    console.log(date);
    return date;
  };

  const downloadExcel=()=>{
    const workSheet=utils.json_to_sheet(rows)
    const workBook=utils.book_new()
    utils.book_append_sheet(workBook,workSheet,"students")
    //Buffer
    let buf=write(workBook,{bookType:"xlsx",type:"buffer"})
    //Binary string
    write(workBook,{bookType:"xlsx",type:"binary"})
    //Download
    writeFile(workBook,`Bookings ${fromDateFormat} : ${toDateFormat}.xlsx`)
  }

  const downloadPdf = () => {
    console.log("booking list ===>>>>>>>", bookingList)
    const doc = new jsPDF('landscape')
    doc.text(`Bookings ${fromDateFormat} : ${toDateFormat}`, 20, 10)
    doc.autoTable({
      theme: "grid",
      // columns: columns.map(col => ({ ...col, dataKey: col.id })),
      columns: columnsForPdf,
      body: rows,
      // overflow:'linebreak'
      styles:{
        // overflow:'visible',
        // cellWidth:50
        // cellWidth:50
      },
      columnStyles: { 0: {cellWidth:30 } },
    })
    doc.save(`Bookings ${fromDateFormat} : ${toDateFormat}.pdf`)
  }

  return (
    <>
      <Header />
      <Container style={{margin:"3% 0% ", display:"inline-flex", width:"35%", justifyContent:"space-between"}}  >
        <Typography>From: </Typography>
      <DatePicker
        onChange={(e) => {
          setFromDate(e);
          setFromDateFormat(getFormattedDate(e));
        }}
        value={fromDate}
        format="dd-MM-y"
        
      />
      <Typography>To: </Typography>
      <DatePicker
        onChange={(e) => {
          setToDate(e);
          setToDateFormat(getFormattedDate(e));
        }}
        value={toDate}
        format="dd-MM-y"
        style={{zIndex:10}}
      />
      </Container>
      <Container style={{margin:"0% 0% "}}>
        <Tooltip title="Export as Excel">
      <DownloadForOfflineIcon sx={{ fontSize: 35 }} onClick={()=> downloadExcel()}  ></DownloadForOfflineIcon>
      </Tooltip>
      <Tooltip title="Export as PDF">
      <PrintIcon sx={{ fontSize: 35 }} onClick={()=> downloadPdf()} ></PrintIcon>
      </Tooltip>
      </Container>
      
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table >
            <TableHead  style={{zIndex:0}}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
