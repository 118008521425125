import React, { useEffect } from "react";
import { useState } from "react";

// import axios from "axios";
import Api from "../Utils/Api";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  // MDBIcon,
  // MDBCheckbox,
} from "mdb-react-ui-kit";

function Login() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (localStorage.getItem("titostoken")) {
      window.location.href = "/dashboard";
    }
  }, []);

  const handleSubmit = () => {
    var data = {
      Username: email,
      Password: password,
    };

    Api.post("admin/adminlogin", data).then((res) => {
      if (res.data.Error) {
        if (
          res.data.Error.ErrorCode == 10006 ||
          res.data.Error.ErrorCode == 10005
        ) {
          alert("Please enter valid username and password!");
        }
      } else {
        console.log(res.data);
        localStorage.setItem("titostoken", res.data.Details.AuthToken);
        localStorage.setItem("titosuserref", res.data.Details.UserRef);
        window.location.href = "/dashboard";
      }
    });
  };

  return (
    <MDBContainer fluid>
      <MDBRow className="d-flex justify-content-center align-items-center h-100">
        <MDBCol col="12">
          <MDBCard
            className="bg-white my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "500px" }}
          >
            <MDBCardBody className="p-5 w-100 d-flex flex-column">
              <h2 className="fw-bold mb-2 text-center">Log in</h2>
              <p className="text-white-50 mb-3">
                Please enter your login and password!
              </p>

              <MDBInput
                wrapperClass="mb-4 w-100"
                label="Email address"
                id="formControlLg"
                type="email"
                size="lg"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                
              />
              <MDBInput
                wrapperClass="mb-4 w-100"
                label="Password"
                id="formControlLg"
                type="password"
                size="lg"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                
              />

              {/* <MDBCheckbox name='flexCheck' id='flexCheckDefault' className='mb-4' label='Remember password' /> */}

              <MDBBtn
                onClick={() => {
                  if (email === "" || password === "")
                    alert("Please enter details: ");
                  else handleSubmit();
                }}
                size="lg"
              >
                Login
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Login;
